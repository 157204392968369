export enum ItemHeaderTab {
  EDIT = 'edit',
  POSITION = 'position',
}

export enum ColorFillOption {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export const COLOR_OBJECT_IDS = [ColorFillOption.PRIMARY, ColorFillOption.SECONDARY, ColorFillOption.TERTIARY];
