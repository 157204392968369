import type {ReactElement} from 'react';
import React, {useEffect} from 'react';
import {Icon} from '@Components/icon-v2';
import {Text, TextSize} from '@Components/text';
import {hideMessageGrowl, updateMessageGrowl} from '@Components/message-growl/message-growl-reducer';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import type {ShowMessageGrowlProps} from './message-growl.types';
import {GROWL_TYPE} from './message-growl.types';
import {useAppDispatch, useAppSelector} from '@/hooks';
import styles from './message-growl.module.scss';

export function MessageGrowl(): ReactElement {
  const message = useAppSelector((state) => {
    return state.messageGrowl;
  });
  const loadingToast = useAppSelector((state) => {
    return state.loadingToast;
  });
  const currentLoading = useAppSelector((state) => {
    return state.loadingToast.currentLoading;
  });
  const isPosterVideo = useAppSelector((state) => {
    return state.posterEditor.isPosterVideo;
  });
  const isSaveReminderDisplaying = useAppSelector((state) => {
    return state.saveReminder.show;
  });
  const bottomBarHeight = useAppSelector((state) => {
    return state.posterEditorWebBottomBar.bottomBarHeight;
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (message.show) {
      setTimeout(() => {
        dispatch(hideMessageGrowl(message.key));
      }, message.interval);
    }
  }, [message.show]);

  const getLeftBarBackgroundColor = (): string => {
    switch (message.type) {
      case GROWL_TYPE.DANGER:
        return styles.leftBarDanger;
      case GROWL_TYPE.PREMIUM:
        return styles.leftBarPremium;
      case GROWL_TYPE.SECONDARY:
        return styles.leftBarSecondary;
      case GROWL_TYPE.NEUTRAL:
        return styles.leftBarNeutral;
      case GROWL_TYPE.SUCCESS:
      default:
        return styles.leftBarSuccess;
    }
  };

  const getClasses = (): string => {
    let loadingToastClass = currentLoading.show ? styles.displayingLoadingToast : '';
    if (currentLoading.show && currentLoading.progress) {
      loadingToastClass = styles.displayingLoadingToastWithProgress;
    }
    return `${styles.container} flex-row-justify-center radius-4 content-body spacing-p-r-2 _fit-width _fit-height
            ${message.show ? styles.in : styles.out}
            ${isSaveReminderDisplaying || loadingToast.isSaveReminderDisplaying ? styles.displayingSaveReminder : ''} 
            ${isPosterVideo || loadingToast.isProgressBarDisplaying ? styles.displayingSeekbar : ''}
            ${loadingToastClass}`;
  };

  const getBottom = (): string => {
    let val = 0;
    const DEFAULT_GAP_OR_PADDING = 16;
    const DEFAULT_BOTTOM_BAR_HEIGHT_WITH_PADDING = 76;
    const DEFAULT_SAVE_REMINDER_HEIGHT = 137;
    if (!isPosterVideo) {
      val = DEFAULT_GAP_OR_PADDING;
    } else {
      val = bottomBarHeight ? bottomBarHeight + DEFAULT_GAP_OR_PADDING : DEFAULT_BOTTOM_BAR_HEIGHT_WITH_PADDING;
    }

    if (isSaveReminderDisplaying || loadingToast.isSaveReminderDisplaying) {
      val += DEFAULT_SAVE_REMINDER_HEIGHT + DEFAULT_GAP_OR_PADDING;
    }

    return `${val}px`;
  };

  return (
    <div
      style={{
        bottom: getBottom(),
      }}
      className={getClasses()}
    >
      <div className={`${styles.leftBar} ${getLeftBarBackgroundColor()}`} />
      <div className="flexbox spacing-p-t-2 spacing-p-b-2">
        {message.type === GROWL_TYPE.DANGER ? (
          <div className="_fit-width _fit-height">
            <Icon icon="icon-exclamation-triangle" className="spacing-p-l-3 content-danger" type={IconType.NONE} size={IconSize.SIZE_ICON_16} shape={IconShape.NONE} />
          </div>
        ) : null}
        <Text className={`${styles.text} spacing-p-l-3`} size={TextSize.SMALL} val={message.text} dangerouslySetInnerHTML />
        {message.showCloseIcon ? (
          <div>
            <Icon
              icon="icon-close"
              type={IconType.GHOST}
              shape={IconShape.SQUARE}
              size={IconSize.SIZE_ICON_16}
              onClick={(): void => {
                dispatch(hideMessageGrowl(message.key));
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export const showMessageGrowl = (message: ShowMessageGrowlProps): void => {
  window.PMW.redux.store.dispatch(
    updateMessageGrowl({
      key: message.key,
      text: message.text,
      type: message.type,
      interval: message.interval,
      showCloseIcon: message.showCloseIcon,
      icon: message.icon,
      dangerouslySetInnerHTML: message.dangerouslySetInnerHTML,
    })
  );
};
