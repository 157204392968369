import type {ReactElement} from 'react';
import React from 'react';
import {PMW_COLORS_NEUTRAL} from '@Utils/color.util';
import styles from './color-fill.module.scss';

interface ColorFillProps {
  id?: string;
  color: string | Array<string>;
  className?: string;
  height?: number;
  width?: number;
  hasFocus?: boolean;
  colorFillPropertyType?: ColorFillPropertyType;
  isDisabled?: boolean;

  onClick?(id?: string): void;
}

export enum ColorFillPropertyType {
  SOLID = 'SOLID',
  RADIAL = 'RADIAL',
  LINEAR = 'LINEAR',
}

const DEFAULT_COLOR_FILL_WIDTH = 50;
const DEFAULT_COLOR_FILL_HEIGHT = 30;

export function ColorFill({
  id = '',
  className = '',
  height = DEFAULT_COLOR_FILL_HEIGHT,
  width = DEFAULT_COLOR_FILL_WIDTH,
  hasFocus = true,
  onClick = (): void => {},
  colorFillPropertyType = ColorFillPropertyType.SOLID,
  isDisabled = false,
  ...props
}: ColorFillProps): ReactElement | null {
  const isString = (): boolean => {
    return typeof props.color === 'string';
  };

  const isArray = (): boolean => {
    return Array.isArray(props.color);
  };

  const getBackground = (): string => {
    if (isDisabled) {
      return PMW_COLORS_NEUTRAL.NEUTRAL_11;
    }
    switch (colorFillPropertyType) {
      case ColorFillPropertyType.SOLID:
        return isString() ? (props.color as string) : '';
      case ColorFillPropertyType.LINEAR:
        return isArray() ? `linear-gradient(${props.color[1]}, ${props.color[0]})` : '';
      case ColorFillPropertyType.RADIAL:
        return isArray() ? `radial-gradient(${props.color[1]}, ${props.color[0]})` : '';
      default:
        return '';
    }
  };

  return (
    <span
      onClick={(): void => {
        if (isDisabled) {
          return;
        }
        onClick(id);
      }}
      id={id}
      style={{
        background: getBackground(),
        backgroundImage: getBackground(),
        width,
        height,
      }}
      className={`${className} ${styles.container} ${isDisabled ? styles.disabled : ''} ${hasFocus && !isDisabled ? '' : styles.noFocus}`}
    />
  );
}
