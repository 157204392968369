import type {AUTH_MODE, AUTH_SUB_TYPE, AUTH_TYPE} from '@Libraries/login-signup-library';

export type RecaptchaTypes = string | number | string[] | undefined;
export type VoidFunction = () => void;
export type GoogleSignInCallback = ((arg0: string) => Promise<void>) | VoidFunction;

type SynchronousFacebookConnectCallback = (arg0: string, arg1?: boolean) => void;
type AsyncFacebookConnectCallback = (arg0: boolean) => Promise<void>;
export type FacebookConnectCallback = SynchronousFacebookConnectCallback | AsyncFacebookConnectCallback | VoidFunction;

export type AuthFormSubmitSuccessCallback = VoidFunction | ((userId?: number) => void);
export type ProcessAsyncLoginSuccessType = (response: AsyncLoginSuccessResponse, callback: AuthFormSubmitSuccessCallback) => void;

export interface ErrorData {
  errorMessage: string;
  showError: boolean;
}

export interface ErrorNotificationData extends ErrorData {
  animateNotificationBanner: boolean;
}

export interface LoginPageState extends ErrorNotificationData {
  authType: AUTH_TYPE;
  authMode: AUTH_MODE;
  isInDataRegulatedLocation: boolean;
  areAuthButtonsDisabled: boolean;
  encryptedEmail?: string;
  encryptedPassword?: string;
  projectName?: string;
  confirmLoginType: AUTH_TYPE;
  signupSource: string;
  signupActionType?: string;
  loginActionType?: string;
  authSubType?: AUTH_SUB_TYPE;
  showAuthForm: boolean;
  isLoading: boolean;
  redirectUrl?: string;
  isLoginToProceedMessage?: boolean;
  isConnectSSOAccountModal?: boolean;
}

export interface ConfirmLoginState {
  encryptedEmail?: string;
  encryptedPassword?: string;
  projectName?: string;
  confirmLoginType: AUTH_TYPE;
}

export interface AuthTypes {
  type: AUTH_TYPE;
  subType?: AUTH_SUB_TYPE;
}

export interface LoginPageInitializeProps extends ErrorData {
  isInDataRegulatedLocation: boolean;
  signupActionType?: string;
  loginActionType?: string;
  redirectUrl?: string;
  isLoginToProceedMessage?: boolean;
  isOauthFlow: boolean;
}

export interface CommonAuthFormParams {
  teamName?: string;
  idTeam?: string;
  invite?: string;
  redirecturl?: string;
  loginActionType?: string;
  signupActionType?: string;
}

export interface OauthFormBaseParams {
  clientName?: string;
  clientId?: string;
  responseType?: string;
  redirectUri?: string;
  state?: string;
}

interface BaseLoginParams {
  email: string;
  password: string;
}

interface BaseLoginParamsWithRecaptcha extends BaseLoginParams {
  gRecaptchaResponse: RecaptchaTypes;
}

export interface WebLoginParams extends CommonAuthFormParams, BaseLoginParamsWithRecaptcha {
  studentlogin: number;
}

export interface OauthLoginParams extends OauthFormBaseParams, BaseLoginParams {}

export interface AsyncLoginParams extends BaseLoginParamsWithRecaptcha {
  isRecaptchaV2Confirmation: number;
}

export interface StudentWebLoginParams extends CommonAuthFormParams {
  project: string;
  gRecaptchaResponse: RecaptchaTypes;
  isRecaptchaV2Confirmation: number;
  studentlogin: number;
}

export interface AsyncStudentLoginParams {
  project: string;
  gRecaptchaResponse: RecaptchaTypes;
}

export interface WebSignupParams extends CommonAuthFormParams, BaseLoginParamsWithRecaptcha {
  confirmPassword: string;
  emailUpdates: boolean;
}

export interface AsyncSignupParams extends BaseLoginParamsWithRecaptcha {
  confirmPassword: string;
  signupSource: string;
  emailUpdates: boolean;
}

export interface ForgotPasswordParams {
  email: string;
}

export interface ConfirmLoginWebParams extends CommonAuthFormParams {
  loginEmail?: string;
  loginPassword?: string;
  project?: string;
  studentlogin: number;
  gRecaptchaResponseV2: string;
  isRecaptchaV2Confirmation: string;
}

export interface AsyncConfirmLoginParams {
  email?: string;
  password?: string;
  gRecaptchaResponse: string;
  isRecaptchaV2Confirmation: string;
}

export enum AuthFormSubmissionType {
  WEBLOGIN = 'web_login',
  OAUTHLOGIN = 'oauth_login',
  ASYNCLOGIN = 'async_login',
  ASYNCSTUDENTLOGIN = 'async_student_login',
  WEBSIGNUP = 'web_signup',
  ASYNCSIGNUP = 'async_signup',
  FORGOTPASSWORD = 'forgot_password',
  LOGGEDINFORGOTPASSWORD = 'logged_in_forgot_password',
  WEBCONFIRMLOGIN = 'web_confirm_login',
  ASYNCCONFIRMLOGIN = 'async_confirm_login',
}

export interface AuthFormSubmissionParams {
  type: AuthFormSubmissionType;
  requestParams:
    | WebLoginParams
    | AsyncLoginParams
    | OauthLoginParams
    | AsyncStudentLoginParams
    | StudentWebLoginParams
    | WebSignupParams
    | AsyncSignupParams
    | ForgotPasswordParams
    | ConfirmLoginWebParams
    | AsyncConfirmLoginParams;
}

export interface BaseAuthFormProps {
  showSmallButton?: boolean;
  onAuthFormSubmitSuccess?: AuthFormSubmitSuccessCallback;
}

export enum LoginSignupPageAssetName {
  BACKGROUND_TESTIMONY_SHAPES = 'login-signup-testimony-background-shapes.png',
  BACKGROUD_TESTIMONY_SHAPES_TRANSITION = 'login-signup-testimony-background-shapes-transition.png',
  MUSIC_PROMOTER_TESTIMONY = 'login-signup-testimony-image-music-promoter-pmw-background.png',
  BLOGGER_TESTIMONY = 'login-signup-testimony-image-blogger-pmw-background.png',
  PRINCIPAL_TESTIMONY = 'login-signup-testimony-image-principal-pmw-background.png',
  QUOTE_ICON = 'login-signup-testimony-quote-icon.png',
}

export interface AsyncLoginSuccessResponse {
  pendingMFA: boolean;
  id?: number;
}

export interface AsyncLoginFailResponse {
  payload: {
    data: {
      reason: string;
      isRecaptchaV2Confirmation?: number;
    };
  };
}

export interface WebLoginSuccessResponse {
  redirectURL?: string;
  project?: string;
  email?: string;
  password?: string;
  pendingMFA?: boolean;
}

export interface LoginSuccessResponse extends WebLoginSuccessResponse {
  id?: number;
  status?: number;
}

export interface AsyncStudentLoginErrorResponse {
  data?: {
    status: string;
  };
}

export interface LoginErrorResponse {
  data?: {
    redirectUrl?: string;
    redirectURL?: string;
    reason?: string;
    message?: string;
  };
  status?: string;
}
