import type {TemplateSubmissionFlowContainerQuery} from '@Components/template-submission-flow/template-submission-flow.types';
import {SubmissionFlowStep} from '@Components/template-submission-flow/template-submission-flow.types';
import {IconPlacement} from '@Components/button';

export const getNavButtonIcon = (currentStep: SubmissionFlowStep, isImproveTemplatesFlow: boolean): string => {
  if (currentStep === SubmissionFlowStep.TAGS || isImproveTemplatesFlow) {
    return 'icon-submit-design';
  }
  if (currentStep === SubmissionFlowStep.IN_REVIEW) {
    return 'icon-designs';
  }
  return 'icon-arrow-right';
};

export const getNavButtonIconPlacement = (currentStep: SubmissionFlowStep): IconPlacement => {
  switch (currentStep) {
    case SubmissionFlowStep.IN_REVIEW:
      return IconPlacement.LEFT;
    default:
      return IconPlacement.RIGHT;
  }
};

export const getNavButtonText = (currentStep: SubmissionFlowStep, isImproveTemplatesFlow: boolean): string => {
  if (currentStep === SubmissionFlowStep.TAGS || isImproveTemplatesFlow) {
    return window.i18next.t('pmwjs_submit');
  }
  if (currentStep === SubmissionFlowStep.IN_REVIEW) {
    return window.i18next.t('pmwjs_go_to_my_stuff');
  }
  return window.i18next.t('pmwjs_next');
};

export const getCurrentPageHeading = (currentStep: SubmissionFlowStep): string => {
  switch (currentStep) {
    case SubmissionFlowStep.NAME:
      return window.i18next.t('pmwjs_template_name');
    case SubmissionFlowStep.TAGS:
      return window.i18next.t('pmwjs_tags');
    case SubmissionFlowStep.IN_REVIEW:
      return window.i18next.t('pmwjs_template_under_review');
    default:
      return window.i18next.t('pmwjs_design_style');
  }
};

export const getCurrentPageSubheading = (currentStep: SubmissionFlowStep): string => {
  switch (currentStep) {
    case SubmissionFlowStep.NAME:
      return window.i18next.t('pmwjs_template_name_subheading');
    case SubmissionFlowStep.TAGS:
      return window.i18next.t('pmwjs_tags_subheading');
    case SubmissionFlowStep.IN_REVIEW:
      return window.i18next.t('pmwjs_template_under_review_subheading');
    default:
      return window.i18next.t('pmwjs_design_style_subheading');
  }
};

export const isLargeScreen = (containerQuery: TemplateSubmissionFlowContainerQuery): boolean => {
  return (containerQuery.isLaptopScreen || containerQuery.isDesktopScreen) as boolean;
};

export const goToActionCenter = (): void => {
  window.location.href = window.PMW.util.site_url('posters/mine#/improve-templates');
};

export const goToPosterViewPage = (selectedPosterHashedId: string): void => {
  window.location.href = window.PMW.util.site_url(`poster/view/${selectedPosterHashedId}`);
};

export const getSelectedDesignStylesForAjax = (selectedDesignStyles: Record<string, boolean>): string[] => {
  return Object.keys(selectedDesignStyles).filter((designStyleName) => {
    return selectedDesignStyles[designStyleName];
  });
};
